/* eslint-disable sort-keys */
export const translation = {
  Button: {
    Edit: '編集',
    Cancel: 'キャンセル',
  },

  ComingSoon: {
    ReleaseDate: '近日開始予定',
    RedeemAccessKeys:
      '近日中に、このページからCompTIAトレーニング製品のアクセスキーが登録できるようになります。最新情報をご確認ください。',
    AccessYourTraining:
      '近日中に、このページからCompTIAトレーニング製品にアクセスできるようになります。最新情報をご確認ください。',
    BrowseTraining: 'トレーニングを閲覧する',
  },

  Loading: {
    Default: 'しばらくお待ちください',
    AddressVerification: 'アドレスを認証しています',
    RedeemingAccessKey: 'アクセスキーを確認しています',
    UpdatingProfile: 'プロフィールを更新しています',
    UpdatingEmail: 'Eメールを更新しています',
    UpdatingPassword: 'パスワードを更新しています',
    SendingVerificationCode: '確認コードの送信',
    ResendingVerificationCode: '新しい確認コードの送信',
  },

  Toast: {
    Action: {
      Dismiss: '了解です',
    },
    Error: {
      Default: '予期しないエラーが発生しました。後ほどお試しください。',
    },
    Success: {
      EditProfile: 'プロフィールが更新されました',
      EditEmail:
        'お客様のメールアドレスは正常に変更されました。新しいメールアドレスでサインインするためログインページに移動します。',
      EditPassword: 'パスワードが更新されました',
      VerificationCodeSent:
        '新しいメールアドレスに確認コードが送信されました。次のステップで確認コードを入力しメールアドレスを変更してください。',
    },
  },

  Validation: {
    AlternateEmail: '異なるEメールを使用してください',
    CurrentEmail: '無効なEメールです',
    CurrentPassword: '現在のパスワードとは異なる必要があります',
    InvalidAddress: '無効な住所です',
    InvalidCity: '無効な市町村です',
    InvalidCountry: '無無効な国です',
    InvalidCountryCode: '無効な国コードです',
    InvalidName: '無効な氏名です',
    InvalidPassword:
      '10～24文字で、次のうち3つの要素を含むこと：小文字、大文字、数字（0～9）、一つ以上の記号：@ # $ % ^ & * - _ + = [] {} | \\ : \' , ? / ` ~ " () ; ! .',
    InvalidPhoneNumber: '無効な電話番号です',
    InvalidPostalCode: '無効な郵便番号です',
    InvalidState: '無効な当道府県です',
    MaximumLength: '最大長を超えています',
    PasswordsMatch: 'パスワードが一致しません',
    PrimaryEmail: 'メインのEメールと異なる必要があります',
    PrimaryPhoneNumber: 'メインの電話番号と異なる必要があります',
    Required: '必須',
    ValidEmail: 'Eメールに誤りがあります',
    ValidBirthDay: '無効な誕生日',
    validMobilePhoneNumber:
      'SMS メッセージを受信するには携帯電話番号を入力してください',
  },

  PearsonError: {
    Button: 'ホームページに戻る',
    Default:
      'ピアソンVUEのシステムで、予期しないエラーが発生しました。カスタマサポートまでご連絡ください。',
    CandidateNotFound:
      'ピアソンVUEの受験者情報が見つかりません。カスタマーサポートまでご連絡ください。',
    ClientNotSupported:
      'ピアソンVUEのクライアントがサポートされていません。カスタマーサポートまでご連絡ください。',
    InvalidPayload:
      'ピアソンVUEのペイロードで認証ハッシュ値が一致しません。カスタマーサポートまでご連絡ください。',
    MissingRequiredParameters:
      '必要となるピアソンVUEのペイロードパラメータがクライアントから提供されませんでした。カスタマーサポートまでご連絡ください。',
    RegistrationNotFound:
      'ピアソンVUEの試験登録番号が見つかりません。カスタマーサポートまでご連絡ください。',
    SessionTimeout:
      'ピアソンVUEへのリクエストがタイムアウトしました。カスタマーサポートまでご連絡ください。',
    Temporary:
      'ピアソンVUEのWebサイトで、一時的に計画停止が発生しています。サポートが必要な場合は、コールセンターにお問い合わせください。',
    VueWideBlock:
      'この制限についてのお問い合わせおよび解決につきましては、ピアソンVUEのカスタマーサービスチーム（0120-355-173）に直接お問い合わせください。<br><br>また、その他のピアソンVUEの連絡方法（お問い合わせフォームを含む）は、<a href="https://www.pearsonvue.co.jp/Test-takers/Customer-service.aspx" style="outline: none;" target="_blank">こちら</a>をご覧ください。',
  },

  FullfillmentBanner: {
    'Title-1': '認定証が発送待ちです。送付先住所を確認してください。',
    Button: '住所を確認する',
  },

  AddressVerificationModal: {
    Title: '送付先住所の確認がされると、発送作業が始まります。',
    SuccessMessage: '住所が確認済みで、認定キットが郵送されます。',
    Button: '住所を確認する',
  },

  ProfileMenu: {
    CompTIA: 'CompTIA ID',
    'Nav-1': 'マイプロフィール',
    'Nav-2': 'ログイン情報',
    'Nav-3': 'マイトレーニング',
    Button: 'サインアウト',
    'Link-1': '法的',
  },

  Navigation: {
    'Link-1': '試験予約',
    'Link-2': '試験の管理',
    'Link-3': '資格の管理',
    'Link-4': 'トレーニングにアクセス',
    'Link-5': 'アクセスキーの登録',
    'Link-Return': 'ホームに戻る',
    'Link-GoBack': '戻る',
  },

  WelcomeHeading: 'ようこそ、',

  ExamRegistration: {
    Accommodations:
      '<b><a target="_blank" href="https://www.pearsonvue.co.jp/Test-takers/Accommodations.aspx">テスト宿泊施設のリクエスト</a></b>',
    Title: '試験を予約する',
    Content: 'テストセンターやオンラインで受験できます',
    'List-1': 'テストセンターまたはオンラインを選択',
    'List-2': '日にちと時間を選択',
    'List-3': '試験ポリシーを確認',
    Button: '予約する',
    'Button-2': 'さらに見る',
    Blocked:
      'お客様のアカウントは、CompTIA 認定資格試験への登録が一時的に停止されています。この停止措置に関するお問い合わせは、 <a href="mailto:security@comptia.org">security@comptia.org</a> までご連絡いただき、CompTIA ID 番号をお伝えください。お手数をおかけしますが、どうぞよろしくお願いいたします。',
  },

  PearsonVueDashboard: {
    Title: '試験を管理する',
    Content: 'テストセンターやオンラインで受験できます',
    'List-1': 'オンライン試験のキャンセルまたは変更',
    'List-2': '予約したオンライン試験を始める',
    'List-3': 'スコアレポートの閲覧およびダウンロード',
    Button: '試験履歴',
    'Button-2': '試験オプション',
  },

  CertMetricsAccount: {
    Title: '資格を管理する',
    Content: '資格を閲覧および管理する',
    'List-1': '資格状況の閲覧',
    'List-2': 'デジタルバッジの発行',
    'List-3': 'CE費用の支払いとCEU申請',
    'List-4': '資格のPDFダウンロード',
    Button: '資格履歴',
    'Button-2': '資格を閲覧する',
  },

  AccessYourTraining: {
    Title: 'トレーニングにアクセス',
    Content: 'Study Guide, Labs, eLearningコースにアクセスする',
    Content2:
      'ここにあなたのコースが表示されませんか? 場合によっては、このログイン ポータルの外部からあなたのコースにアクセスできることがあります。<a href="https://help.comptia.org/hc/en-us/articles/13934951396500-How-Do-I-Access-Training-Products-Through-My-CompTIA-SSO-Account" rel="noopener noreferrer" target="_blank">詳細はこちら</a>。',
    Content3:
      '最新の CertMaster Learn、Perform、Labs コース、および従来の TestOut "Pro" コースにアクセスするには、<a href="https://labsimapp.testout.com/" rel="noopener noreferrer" target="_blank">こちらでサインイン</a>してください。',
    Button: '開始する',
    'Link-1': 'CertMaster Labs',
    'Link-2': 'CertMaster Practice',
    'Link-3': 'Student & Instructor Guides',
    'Link-4': 'CertMaster Learn',
    'Link-5': 'Self-Paced Study Guides',
    'Link-6': 'CertMaster CE',
    'Link-7': 'CompTIA Labs',
    'Link-8': 'CompTIA Direct - Live Online Training',
  },

  RedeemInformation: {
    Content:
      'ストア購入の場合、アクセスキーは注文完了後にEメールで送信され、さらにご自身のアカウントの注文履歴から確認することもできます。研修用教材の場合、アクセスキーは通常インストラクターまたは教育機関の担当者から配布されます。',
    Title: 'アクセスコードをお探しですか？',
  },

  RedeemModule: {
    Label: 'コード入力',
    Button: '今すぐ登録',
    Content:
      '製品のアクセスキーを入力し、今すぐ登録のボタンから製品へのアクセスを始めます。',
    Title: 'アクセスキーの登録',
    NoProducts: 'このアクセス キーは、最大償還限度に達しました。',
    Details: {
      Content: '登録の前に、アクセスキーには所属する組織名と国の情報が必要です',
      Cta: '続ける',
    },
    Demographics: {
      Content:
        'アクセス キーの引き換えプロセスを完了するには、次の情報を入力してください。',
      Cta: '続ける',
    },
    Products: {
      Content:
        'アクセス キーの引き換えプロセスを完了するには、会社と国を入力してください。',
      Label: '製品を選択する',
      Cta: '続ける',
    },
    Success: {
      Content:
        'アクセスキーの登録が完了しました。新しいトレーニング製品へのアクセスまたは購入済み製品の確認ができます。',
      ContentPopuliWaitlist:
        'アクセス キーの引き換えが完了しました。コース登録同意書が記載された電子メールが間もなく送信されます。あなたと学校関係者が署名すると、コースのコンテンツにアクセスできるようになります。',
      ContentPopuliAutoEnrolled:
        'アクセス キーが正常に引き換えられました。すべてのシステムが同期するまで 20 分ほどお待ちください。この Web サイトの「トレーニングにアクセスする」セクションにあるリンクに従ってコースに進んでください。',
      Cta: '新しいトレーニングにアクセスする',
      ViewAll: '購入済み製品を確認する',
    },
  },

  NeedHelp: {
    Title: 'お困りですか？',
    Content: 'FAQを確認してカスタマーサポートに連絡する',
    'List-1': '資格を更新のためのCEUの獲得方法',
    'List-2': '資格試験の予約方法',
    'List-3': '認定証の発送方法',
    Button: 'ヘルプ',
  },

  ExploreLaunchpad: {
    Title: 'CompTIA Launchpadは、試験に関わる全ての情報の単一ソースです',
    Content: 'ご自身のCompTIAトレーニングや認定資格に一度のログインでアクセス ',
    Button: 'Launchpadを見てみる',
  },

  OnboardingSecure: {
    Button: 'さらに見る',
    Title: 'セキュア',
    Content:
      'CompTIA Launchpadでは、ご利用の製品やサービス、試験や資格情報に簡単かつセキュアにアクセスできます。 ',
    'Title-2': 'セキュア',
    'Content-2': '',
  },

  OnboardingConnected: {
    Button: 'さらに見る',
    Title: 'コネクテッド',
    Content:
      'CompTIA Launchpadでは、ご自身の過去、現在、未来の試験情報を確認することができます。全てが一つのアカウントにリンクされています。',
    'Title-2': 'コネクテッド',
    'Content-2': '',
  },

  OnboardingSimple: {
    Button: 'さらに見る',
    Title: '簡単',
    Content:
      '簡単なログイン、業界トップの暗号化で、CompTIA Launchpadでのあらゆる管理を可能にします。',
    'Title-2': '簡単',
    'Content-2': '',
  },

  'FooterColumn-1': {
    Title: 'お問い合わせ',
    'Link-1': 'ヘルプセンター',
    'Link-2': 'アカウント統合のリクエスト',
    'Link-3': '氏名変更のリクエスト',
  },

  'FooterColumn-1-Links': {
    'Link-1': '/help?returnto=https://help.comptia.org/hc/ja',
    'Link-2':
      '/help?returnto=https://help.comptia.org/hc/ja/articles/115005364083-%E8%A4%87%E6%95%B0%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AE%E7%B5%B1%E5%90%88',
    'Link-3':
      '/help?returnto=https://help.comptia.org/hc/ja/articles/115005363983-%E6%B0%8F%E5%90%8D%E3%81%AE%E5%A4%89%E6%9B%B4%E3%81%BE%E3%81%9F%E3%81%AF%E4%BF%AE%E6%AD%A3',
  },

  'FooterColumn-2': {
    Title: '認定資格',
    'Link-1': 'CompTIA認定資格',
    'Link-2': 'トレーニング製品',
    'Link-3': '継続教育CE',
  },

  'FooterColumn-3': {
    Title: 'Store',
    'Link-1': 'CompTIA Store',
  },

  Profile: {
    Address: {
      Address1: '住所1',
      Address2: '住所2',
      Address3: '住所3',
      AddressType: '住所の種類',
      City: '町',
      Country: '国',
      PostalCode: '郵便番号',
      State: '都道府県',
    },
    AllowCompTIAMarketingContact:
      'CompTIAの製品やサービス、最新情報に関するプロモーション、キャンペーン、新製品の案内を受け取る。CompTIAのセキュアデータベースで管理される個人情報は、私のニーズに応じた製品やサービスを評価するために使用されることに同意します。私はいつでもオプトインの選択を変更する権利を有します。',
    AllowCompTIAPartnerMarketingContact:
      'CompTIAのパートナーから、プロモーション、キャンペーン、新製品の案内、その他有益な情報を受け取る。私はいつでもオプトインの選択を変更する権利を有します。',
    AllowConsentToGatherBirthInformation:
      'CompTIAがあなたの誕生月/年の情報を収集することに同意しますか？',
    AllowConsentToSendSmsTextMessages:
      '私は、CompTIA および関連会社から更新、プロモーション、重要な情報に関する SMS テキスト メッセージを受信することに同意します。',
    AllowConsentToGatherBirthInformation2:
      'CompTIA が私の生まれた日、月、年を収集することに同意します。',
    AllowCredentialReleaseToThirdParty:
      '私は、CompTIAが私の認定資格に付随する情報を第三者機関、政府機関、教育機関に公開することを許可します。私は、CompTIA、その役員、取締役、従業員が、この情報公開に起因する特別/付随/間接/派生的損害またはいかなる損害についても責任を負わないことに同意します。私はいつでもオプトインの選択を変更する権利を有します。',
    AlternateEmail: 'その他のEメール',
    AlternatePhoneNumber: {
      CountryCode: 'コード',
      Phone: 'その他の電話番号',
    },
    BirthMonth: '誕生月',
    BirthYear: '誕生年',
    BirthDay: '誕生日',
    Company: '会社名',
    Email: 'Eメール',
    GivenName: {
      FirstName: '名前',
      Heading: '実名',
      LastName: '名字',
      MiddleName: 'ミドルネーム',
      Suffix: 'サフィックス',
      Title: '敬称',
    },
    IsUsCitizenOlderThanEighteenYears: '米国市民かつ18歳以上ですか？',
    IsOlderThanEighteenYears: 'あなたは18歳以上ですか?',
    MobilePhoneNumber: {
      CountryCode: '国コード',
      Phone: '携帯電話',
    },
    PhoneNumber: {
      CountryCode: '国コード',
      Phone: '電話番号',
    },
    Preferences: {
      Language: '対応言語',
    },
    PreferredName: {
      FirstName: '名前',
      FirstNameQualified: 'ハンドルネーム（名前）',
      Heading: 'ハンドルネーム（氏名）',
      LastName: '名字',
      LastNameQualified: 'ハンドルネーム（名字）',
      MiddleName: 'ミドルネーム',
      Suffix: 'サフィックス',
      Title: '敬称',
    },
  },

  EditAccount: {
    Subheading: 'ログイン情報',
    Required: '必須*',
    Email: {
      Tab: 'メールアドレス',
      Heading: 'メインのEメールアドレス',
      EmailAddress: 'Eメールアドレス',
      ConfirmCurrentPassword: 'パスワードの確認',
      Disclaimer:
        '新しいメールアドレスでサインインするためログインページに移動します。',
      VerificationInstructions:
        '新しいメールアドレスに確認コードが送信されます。',
      Social: 'これはSNSアカウントです。Eメールは変更できません',
      SendVerificationCode: '確認コードを送る',
      ResendVerificationCode: '新しい確認コードを送信',
      VerifyCode: '確認コードを確認する',
      VerificationCode: '確認コード',
      NewPassword: '新しいパスワード',
      Submit: '送信',
    },
    Password: {
      Tab: 'パスワード',
      Heading: 'パスワード',
      CurrentPassword: '現在のパスワード',
      NewPassword: '新しいパスワード',
      ConfirmNewPassword: '新しいパスワードの確認',
      Social: 'これはSNSアカウントです。パスワードは変更できません',
    },
    Cta: '保存',
  },

  EditProfile: {
    Subheading: 'ユーザープロフィール情報',
    LegalName:
      '実名の変更や修正については、カスタマサポートまでご連絡ください。',
    PersonalInformation: '個人情報',
    ContactInformation: '連絡先情報',
    AlternateContactInformation: 'その他の連絡先情報',
    AlternateContactSubheading:
      '将来のアカウント復旧のオプションとして、代替のEメールと電話電話の設定をお勧めします。',
    Cta: '保存',
  },

  PearsonDemographic: {
    Heading: 'Pearson VUE',
    Subheading: 'サインアップ',
    YouMustEnter:
      'リーガルネームは、テストセンターに提示する身分証明書に記載されている通りに正確に入力する必要があります。',
    NameInEnglish: '名字、名前はローマ字で入力してください',
    NoReimbursement:
      '重要：以下の氏名は、受験の際に提示する身分証明書に記載されている氏名と一致する必要があります。一致しない場合、受験することができず、受験料も返金されませんのでご注意ください。',
    Required: '必須*',
    LegalName: '実名',
    PreferredName: 'ハンドルネーム',
    ContactInformation: '連絡先情報',
    Cta: '変更を保存する',
    MissingFields: '必須項目が入力されていません',
  },

  TrainingProducts: {
    Subheading: 'マイトレーニング',
    HeadingTag: '製品',
    ProductType: '製品の種類',
    PersonalAccount: '個人アカウント',
    BusinessAccount: 'ビジネスアカウント',
    ExpiresOn: '有効期限',
    ExpiredOn: '有効期限切れ',
    NoProducts: 'トレーニング製品の登録はありません',
    WaitingOnAgreement: '保留中の同期と契約の完了 (該当する場合)',
  },

  NotFound: {
    Heading: '404 Not Found',
    Subheading: 'ご希望の情報が見つかりませんか？',
    Button: 'ホームに戻る',
  },
};
